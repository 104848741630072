function Starfield(config) {
  config = config || {};

  this.canvas = typeof config.canvas === 'string' ? document.querySelector(config.canvas) : config.canvas;
  this.ctx = this.canvas.getContext('2d');
  this.style = getComputedStyle(this.canvas);

  this.vx = config.vx || 0.05;
  this.vy = config.vy || 0.05;

  // Cache canvas dimensions for faster access
  this._width = parseInt(this.style.width, 10);
  this._height = parseInt(this.style.height, 10);

  this.maxStars = parseInt(this._width * 0.5, 10);
  this.maxRadius = config.maxRadius || 1.5;

  this.shootingStarInterval = config.shootingStarInterval;
  this.lastShootingStar = this.shootingStarInterval ? performance.now() : undefined;
  this.shootingStar = null;

  // Pre-calculate common values
  this.colors = ['255,255,255', '255,255,255', '255,255,255', '255,255,200', '200,200,255', '255,200,200'];
  this.starPool = []; // Object pool for stars

  // Bind resize handler once
  this._resizeHandler = this.onResize.bind(this);
  window.addEventListener('resize', this._resizeHandler);

  this.onResize();
}

Starfield.prototype.star = function star() {
  // Reuse star from pool if available
  if (this.starPool.length > 0) {
    const star = this.starPool.pop();
    this.resetStar(star);
    return star;
  }

  // Create new star if none in pool
  return {
    x: Math.round(Math.random() * this.canvas.width),
    y: Math.round(Math.random() * this.canvas.height),
    r: 0.5 + Math.random() * this.maxRadius,
    l: Math.random(),
    bl: 0.1 * (Math.random() * 6 + 2),
    dl: Math.round(Math.random()) === 1 ? 0.01 : -0.01,
    c: this.colors[Math.floor(Math.random() * this.colors.length)],
    s: Math.random() * 0.05,
  };
};

Starfield.prototype.resetStar = function resetStar(star) {
  star.x = Math.round(Math.random() * this.canvas.width);
  star.y = Math.round(Math.random() * this.canvas.height);
  star.r = 0.5 + Math.random() * this.maxRadius;
  star.l = Math.random();
  star.bl = 0.1 * (Math.random() * 6 + 2);
  star.dl = Math.round(Math.random()) === 1 ? 0.01 : -0.01;
  star.c = this.colors[Math.floor(Math.random() * this.colors.length)];
  star.s = Math.random() * 0.05;
  return star;
};

Starfield.prototype.loadStars = function loadStars() {
  // Return stars to pool
  if (this.stars) {
    this.starPool.push(...this.stars);
  }

  this.stars = new Array(this.numStars);

  let i = this.numStars;
  while (i-- > 0) this.stars[i] = this.star();
};

Starfield.prototype.onResize = function onResize() {
  const ratio = window.devicePixelRatio || 1;

  this.style = getComputedStyle(this.canvas);

  // More efficient parsing of dimensions
  this._width = parseInt(this.style.width, 10);
  this._height = parseInt(this.style.height, 10);

  this.canvas.width = this._width;
  this.canvas.height = this._height;

  // Scale the context to account for pixel ratio
  //this.ctx.scale(ratio, ratio);

  this.canvas.center = {
    x: this._width / 2.1,
    y: this._height / 2.9,
  };

  this.numStars = this.maxStars;

  this.loadStars();
};

Starfield.prototype.draw = function draw(star) {
  // Skip shadow for better performance
  if (!this._fastMode && star.r > 1.5) {
    this.ctx.shadowBlur = star.r * 2;
    this.ctx.shadowColor = 'white';
  }

  this.ctx.beginPath();
  this.ctx.fillStyle = `rgba(${star.c},${star.l})`;
  this.ctx.arc(star.x, star.y, star.r, 0, 2 * Math.PI, false);
  this.ctx.fill();

  // Reset shadow
  if (!this._fastMode && star.r > 1.5) {
    this.ctx.shadowBlur = 0;
  }
};

Starfield.prototype.start = function start() {
  // Enable fast mode on slower devices or with many stars
  this._fastMode = window.innerWidth < 768;

  const tick = function onTick(timeStamp) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let i = this.stars.length;
    while (i-- > 0) {
      const star = this.stars[i];

      this.draw(star);

      if (this.canvas.classList.contains('portal')) {
        const dx = star.x - this.canvas.center.x;
        const dy = star.y - this.canvas.center.y;

        // Calculate angle only once
        star.angle = Math.atan2(dy, dx);
        star.speed = star.speed || Math.random() * 50;
        star.cosAngle = Math.cos(star.angle);
        star.sinAngle = Math.sin(star.angle);

        // Update position
        const movementY = star.s * star.speed + this.vy;
        const movementX = star.s * star.speed + this.vx;
        star.x += star.cosAngle * movementX;
        star.y += star.sinAngle * movementY;

        star.l += star.dl;
        if (Math.abs(star.l - star.bl) >= 0.25) star.dl = -star.dl;

        if (star.x < 0 || star.x > this.canvas.width || star.y < 0 || star.y > this.canvas.height) {
          // Reset star to near the center
          star.x = Math.random() * 400 - 200 + this.canvas.center.x;
          star.y = Math.random() * 400 - 200 + this.canvas.center.y;
          star.r = 1 + Math.random() * this.maxRadius;
          star.angle = null; // Force recalculation
        }

        // Draw once with streak effect
        this.draw(star);
        star.x += star.cosAngle * movementY;
        star.y += star.sinAngle * movementX;
        this.draw(star);
        star.x += star.cosAngle * movementY;
        star.y += star.sinAngle * movementX;
        this.draw(star);
        star.x += star.cosAngle * movementY;
        star.y += star.sinAngle * movementX;
        this.draw(star);
      } else {
        star.y += star.s + this.vy;
        star.x += star.s + this.vx;
        star.l += star.dl;

        if (Math.abs(star.l - star.bl) >= 0.25) star.dl = -star.dl;

        if (star.x < -1 * star.r) {
          star.x = this.canvas.width + star.r;
        } else if (star.x > this.canvas.width) {
          star.x = -1 * star.r;
        }

        if (star.y < -1) {
          star.y = this.canvas.height + star.r;
        } else if (star.y > this.canvas.height + star.r) {
          star.y = -1;
        }
      }
    }

    if (this.shootingStar) {
      var star = this.shootingStar;

      this.draw(star);

      star.y += 3;
      star.x += 7;
      star.l += star.dl;
      star.r -= 0.05;

      if (star.r <= 0) this.shootingStar = undefined;
    } else if (this.shootingStarInterval) {
      if (timeStamp - this.lastShootingStar >= this.shootingStarInterval) {
        this.shootingStar = this.star();
        this.lastShootingStar = timeStamp;
        this.shootingStar.r = 4;
      }
    }

    this.frameId = window.requestAnimationFrame(tick);
  }.bind(this);

  this.frameId = window.requestAnimationFrame(tick);
};

Starfield.prototype.stop = function stop() {
  if (this.frameId) {
    window.cancelAnimationFrame(this.frameId);
    this.frameId = null;
  }
  this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

  // Clean up event listener
  window.removeEventListener('resize', this._resizeHandler);
};

export default function init(canvas, settings) {
  const starfield = new Starfield({
    canvas,
    ...settings,
  });

  starfield.start();

  return function stop() {
    starfield.stop();
  };
}
