import { css } from 'astroturf';
import React from 'react';

import Accenture from '../../../assets/images/brands/accenture.svg';
import BaseCamp from '../../../assets/images/brands/basecamp.svg';
import Google from '../../../assets/images/brands/google.svg';
import Harvard from '../../../assets/images/brands/harvard.svg';
import Nist from '../../../assets/images/brands/nist.svg';
import Shopify from '../../../assets/images/brands/shopify.svg';
import Virgin from '../../../assets/images/brands/virgin.svg';
import Buffer from '../../../assets/images/brands/buffer.svg';
import Zillow from '../../../assets/images/brands/zillow.svg';
import ChakraUI from '../../../assets/images/brands/chakraui.svg';
import Pearson from '../../../assets/images/brands/pearson.svg';

const styles = css`
  .brands {
    position: relative;
    padding: 5rem 2rem 5rem;
    padding-left: calc(2rem + env(safe-area-inset-left, 0));
    padding-right: calc(2rem + env(safe-area-inset-right, 0));
    text-align: center;
    margin: 0 auto;
    max-width: 1100px;
    z-index: 1;
    display: none;

    @media (min-width: 600px) {
      display: block;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      max-width: 60rem;

      color: #d8ecf8;
      background: linear-gradient(0deg, #d8ecf8 0, #98c0ef 100%);
      background-clip: text;
      background-size: 100% 1.5em;
      text-shadow: 0 2px 16px rgba(174, 207, 242, 0.24);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & > div {
      max-width: 50rem;
      position: relative;
      margin: 1rem auto 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      margin-top: 4rem;
      gap: 2rem 3rem;
      filter: invert(1);
    }
  }
`;

function Brands(props) {
  return (
    <div className={[styles.brands, props.negtop ? styles.negtop : '', props.blend ? styles.blend : ''].join(' ')}>
      <h2>Trusted by devs from these companies</h2>
      <div>
        <Shopify width={100} height={28} style={{ marginTop: '-0.2em', filter: 'saturate(0)' }} />
        <Google width={84} height={28} style={{ filter: 'brightness(0)' }} />
        <Zillow width={89} height={26} style={{ filter: 'brightness(0)' }} />
        <ChakraUI width={120} height={32} style={{ filter: 'saturate(0)' }} />
        <BaseCamp width={130} height={40} style={{ filter: 'saturate(0)' }} />
        <Accenture width={100} height={26} style={{ marginTop: '-0.7em', filter: 'brightness(0)' }} />
        <Harvard width={110} height={32} />
        <Virgin width={44} height={38} style={{ filter: 'brightness(0)' }} />
        <Pearson width={100} height={26} />
        <Nist width={70} height={32} />
        <Buffer width={85} height={26} style={{ filter: 'saturate(0)' }} />
      </div>
    </div>
  );
}

export default Brands;
